import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import loanContext from "../../../../Contexts/loan/loanContext";

import { useStyles } from "./style";
import { formatDateTime, validateDates } from "../../../../Actions/helpers";

const PartnersLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [partner, setPartner] = useState(null);
  const [partners, setPartners] = useState({});

  const { setAlert } = useContext(alertContext);
  const { makeFilterRequest } = useContext(loanContext);

  const initialState = {
    orgName: "",
    orgCode: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState({ ...initialState });
  const { pageSize, pageNumber, startDate, endDate, orgName, orgCode } =
    payload;

  const _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }

    getPartners();

    return () => (_isMounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  ////////////////////////**********MODAL ACTIONS**********///////////////////

  const handleClose = () => {
    setOpen(false);
    partner !== null && setPartner(null);
  };
  ////////////////////////**********MODAL ACTIONS**********///////////////////

  ///////////////////////***********PARTNER CREATE ACTIONS***********//////////////

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);

  const handleMenuOpen = (e, partner) => {
    setAnchorEl(e.currentTarget);
    console.log(partner);
    setPartner(partner);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /////////////////**********TABLE ACTIONS *************////////////////
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    ///always reset pageNumber if pageSize or name changes to avoid wrong data fetch
    if (name === "pageSize" || name === "orgName") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handlePartnerNameSearch = (e) => {
    setPayload({ ...payload, orgName: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `Organization/?pageSize=${pageSize}&page=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the name field
    setPayload({ ...initialState, orgName });
    filter = true;
    if (orgName !== "") {
      url += `&orgName=${orgName}`;
    }
    await getPartners(url, filter);
  };

  const getPartnersFromFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getPartners();
  };

  const getPartners = async (
    url = `Organization?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (orgName !== "") {
        url += `&orgName=${orgName}`;
      }

      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (orgCode !== "") {
        url += `&orgCode=${orgCode}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      if (_isMounted.current) {
        setPartners(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const loadWithDebounce = useCallback(
    debounce((orgName) => {
      if (orgName === "") {
        getPartners(url);
      } else {
        getPartners(`${url}&orgName=${orgName}`);
      }
    }, 1000),
    []
  );

  const columns = [
    {
      Header: "Partner's Name",
      accessor: "orgName",
    },
    {
      Header: "Partner Code",
      accessor: "orgCode",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => <StatusIndicator status={props.value} />,
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => formatDateTime(props.value),
    },

    {
      Header: " ",
      Cell: (props) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-haspopup="true"
              aria-controls={props.row.original.id}
              onClick={(e) => handleMenuOpen(e, props.row.original)}
            >
              <MoreVertIcon color="primary" style={{ cursor: "pointer" }} />
            </IconButton>
          </>
        );
      },
    },
  ];

  /////////////////**********TABLE ACTIONS *****************////////////////

  /////////////////**********MODAL VIEWS *****************////////////////
  const filterForm = () => (
    <>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="startDate" className={classes.label}>
              Start Date
            </label>
            <TextField
              name="startDate"
              variant="outlined"
              size="small"
              type="date"
              value={startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="endDate" className={classes.label}>
              End Date
            </label>
            <TextField
              name="endDate"
              variant="outlined"
              size="small"
              type="date"
              value={endDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="orgCode" className={classes.label}>
              Partner's Code
            </label>
            <TextField
              name="orgCode"
              variant="outlined"
              size="small"
              value={orgCode}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box className={classes.filterActions}>
        <Typography
          style={{ color: "red", cursor: "pointer" }}
          onClick={clearFilters}
        >
          Clear all filters
        </Typography>
        <Button
          variant="contained"
          size="medium"
          disabled={
            loading || (startDate === "" && endDate === "" && orgCode === "")
          }
          loading={loading}
          // type="submit"
          color="primary"
          onClick={() => getPartnersFromFirstPage()}
        >
          Apply
        </Button>
      </Box>
    </>
  );

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">Filter</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>{filterForm()}</Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Partner Management</Typography>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          linkPage="/partners/create"
          to="/partners/create"
        >
          Add partner
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="Total Partners"
            searchItem={orgName}
            onChange={handlePartnerNameSearch}
            loading={loading}
            placeholder="Search By Partner's name"
            totalSize={partners?.totalSize}
            onClick={setOpen}
            handleInputChange={handleInputChange}
          />
          <Box marginTop={"2rem"}>
            {loading && !partners.items ? (
              <p>Loading...</p>
            ) : (!loading && partners.items) || (loading && partners.items) ? (
              <>
                {/* {console.log(users.items)} */}
                <Table
                  columns={columns}
                  data={partners.items}
                  loading={loading}
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {partners.items.length > 0 && (
                      <Typography>
                        Showing page {partners.pageNumber} of{" "}
                        {Math.ceil(partners?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={partners?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={partners.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={anchorOpen}
          onClick={handleMenuClose}
          PaperProps={{
            style: {
              width: "10ch",
            },
          }}
        >
          <Link
            style={{ color: "#000000DE", textDecoration: "none" }}
            to={{
              pathname: "/partner/details",
              state: {
                data: partner,
              },
            }}
          >
            <MenuItem>View</MenuItem>
          </Link>
          <Link
            style={{ color: "#000000DE", textDecoration: "none" }}
            to={{
              pathname: "/partners/create",
              state: {
                data: partner,
              },
            }}
          >
            <MenuItem>Edit</MenuItem>
          </Link>
        </Menu>
      </>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default PartnersLayout;
