import React, { useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./style";
import * as Yup from "yup";
import Paper from "../../../../Paper";
import { ErrorMessage, Form, Formik } from "formik";
import FormErrors from "../../../../FormErrors";
import { DropzoneArea } from "material-ui-dropzone";
import Button from "../../../../Button";
import { useHistory } from "react-router-dom";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { useContext } from "react";
import alertContext from "../../../../../Contexts/alert/alertContext";
import loanContext from "../../../../../Contexts/loan/loanContext";
import { useEffect } from "react";
import AxiosRequest from "../../../../../Services/AxiosRequests";

const AddEditInitiative = (props) => {
  const initiativeDetails =
    props.location?.state?.data && props.location?.state?.data;
  const { name, theImpact, about, status, vetted, organization, id } =
    initiativeDetails || {};

  const statusValue = () => {
    let returnValue = 1;

    switch (status) {
      case "PENDING":
        returnValue = 1;
        break;

      case "LIVE":
        returnValue = 2;
        break;

      case "CLOSE":
        returnValue = 3;
        break;

      case "DEACTIVATE":
        returnValue = 4;
        break;

      default:
        break;
    }

    return returnValue;
  };

  const [isVetted, setIsVetted] = useState(vetted || false);
  const [initiativeStatus, setInitiativeStatus] = useState(statusValue());
  const [isVettedSubmitting, setIsVettedSubmitting] = useState(false);
  const [isStatusSubmitting, setIsStatusSubmitting] = useState(false);

  const [orgType, setOrgType] = useState(
    props.location?.state?.data?.id ? null : "new"
  );
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationLogo, setOrganizationLogo] = useState(null);
  const [initiativeLogo, setInitiativeLogo] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const { setAlert } = useContext(alertContext);
  const { addOrUpdateScholarshipInitiative, makeFilterRequest } =
    useContext(loanContext);

  console.log("These are the vetted statuses: ", vetted, isVetted);

  useEffect(() => {
    const getOrgs = async () => {
      const res = await makeFilterRequest("ScholarshipOrganization");

      if (res.data?.requestSuccessful && res.data?.responseData) {
        setOrganizationList(res.data.responseData.items);
      }
    };

    getOrgs();
  }, []);

  const initialValues = {
    name: name || "",
    theImpact: theImpact || "",
    about: about || "",
    orgId: organization?.orgId || "",
    orgName: "",
    aboutTheOrganization: "",
  };

  const validationSchema = Yup.object({
    orgName:
      orgType === "new" &&
      Yup.string().required("The organization name is empty"),
    aboutTheOrganization:
      orgType === "new" && Yup.string().required("About organization is empty"),
    name: Yup.string().required("The initiative name is empty"),
    theImpact: Yup.string().required("The impact field is empty"),
    about: Yup.string().required("The about initiative field is empty"),
  });

  const submitForm = async (values, actions) => {
    const { about, aboutTheOrganization, name, orgId, orgName, theImpact } =
      values || {};
    console.log(
      "The submit button is called.",
      values,
      organizationLogo,
      initiativeLogo
    );

    if (orgType === "existing" && !orgId) {
      actions.setSubmitting(false);
      return setAlert("Select an organization", "error");
    }

    if (!id && !initiativeLogo) {
      actions.setSubmitting(false);
      return setAlert("Upload an initiative image", "error");
    }

    if (!id && orgType === "new" && !organizationLogo) {
      actions.setSubmitting(false);
      return setAlert("Upload an initiative image", "error");
    }

    try {
      let payload = {};
      if (id || orgType === "existing") {
        payload = {
          name,
          theImpact,
          about,
          orgId,
          initiativeLogo,
        };
      } else if (orgType === "new") {
        payload = {
          orgName,
          aboutTheOrganization,
          initiativeName: name,
          about,
          theImpact,
          organizationLogo,
          initiativeLogo,
        };
      }

      console.log("The submitted response is: ", payload);

      const response = await addOrUpdateScholarshipInitiative(
        payload,
        orgType === "existing",
        id && id
      );
      if (response.data?.requestSuccessful) {
        setAlert("Organization action was successful.", "success");
        actions.setSubmitting(false);
        history.push("/scholarships");
      } else {
        setAlert("Organization action unsuccessful. Please try again", "error");
        actions.setSubmitting(false);
      }
    } catch (error) {}
  };

  const updateVettedStatus = async () => {
    setIsVettedSubmitting(true);
    try {
      const res = await AxiosRequest("post", `Initiatives/is_vetted/${id}`);
      if (res.data.requestSuccessful) {
        setIsVetted((prevProps) => {
          return !prevProps;
        });
        setAlert(
          "Initiative vetted status has been updated successfully.",
          "success"
        );
      } else {
        setAlert(
          res?.data?.message || " Error occured while updating vetted status",
          "error"
        );
      }
    } catch (error) {
      setAlert("Error occured while updating vetted status,", "error");
    }
    setIsVettedSubmitting(false);
  };

  const updateInitiativeStatus = async () => {
    setIsStatusSubmitting(true);
    try {
      const res = await AxiosRequest(
        "post",
        `Initiatives/update_status/${id}?input=${initiativeStatus}`
      );
      if (res.data.requestSuccessful) {
        setAlert(
          res?.data?.message ||
            "The Initiative status has been updated successfully.",
          "success"
        );
      } else {
        setAlert(
          res?.data?.message ||
            "Error occured while updating initiative status",
          "error"
        );
      }
    } catch (error) {
      setAlert("Error occured while updating initiative status,", "error");
    }
    setIsStatusSubmitting(false);
  };

  const StatusLabel = ({ status }) => {
    let text1 = "Pending";
    let text2 =
      "This initiative is in a pending state and is awaiting approval to go live";
    let textColor = "#D49924";
    let bgColor = "#FBF4EA";

    switch (status) {
      case "PENDING":
        text1 = "Pending";
        text2 =
          "This initiative is in a pending state and is awaiting approval to go live";
        textColor = "#D49924";
        bgColor = "#FBF4EA";
        break;

      case "LIVE":
        text1 = "Live";
        text2 =
          "This initiative is in an active state and can be seen by users";
        textColor = "#41D87D";
        bgColor = "#EAFBF1";
        break;

      case "CLOSE":
        text1 = "Closed";
        text2 =
          "This initiative is in a closed state and can not be donated to by users";
        textColor = "#E43535";
        bgColor = "#FCE9E9";
        break;

      case "DEACTIVATE":
        text1 = "Inactive";
        text2 =
          "This initiative is in an inactive state and can not be seen by users";
        textColor = "#F4F4F4";
        bgColor = "#868686";
        break;

      default:
        break;
    }

    return (
      <Box
        style={{ backgroundColor: bgColor, width: "fit-content" }}
        paddingX="20px"
        paddingY="15px"
        borderRadius="3px"
      >
        <span
          style={{ color: textColor, fontWeight: "500", marginRight: "20px" }}
        >
          {text1}
        </span>
        <span
          style={{
            fontSize: "12px",
            fontWeight: "300",
            marginRight: "20px",
          }}
        >
          {text2}
        </span>
      </Box>
    );
  };

  const goBack = () => {
    console.log("The cancel save is implemented");
    history.goBack();
  };

  return (
    <Box>
      <Typography style={{ marginBottom: "0.5rem" }} variant="body1">
        <span style={{ fontWeight: "300" }}>Scholarships</span> /{" "}
        <span style={{ fontWeight: "500" }}>Initiative</span>
      </Typography>
      <Box className={classes.headerTexts}>
        <IconButton
          onClick={goBack}
          aria-label="close"
          className={classes.backButton}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <Typography variant="h4">{`${
          id ? "Edit" : "Add"
        } Initiative`}</Typography>
      </Box>
      <Box>
        <Paper borderRadius={"0"} padding={"1.5rem"}>
          <Box>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                getFieldProps,
                setFieldValue,
                isSubmitting,
              }) => (
                <Form noValidate>
                  {!id && (
                    <Box>
                      <Typography variant="h5">
                        Organization Information
                      </Typography>
                      <Divider style={{ margin: "0.5rem 0rem" }} />

                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        row
                        value={orgType}
                      >
                        <FormControlLabel
                          value="new"
                          control={<Radio color="primary" />}
                          label="New Organization"
                          onChange={() => setOrgType("new")}
                        />
                        <FormControlLabel
                          value="existing"
                          control={<Radio color="primary" />}
                          label="Existing Organization"
                          onChange={() => setOrgType("existing")}
                        />
                      </RadioGroup>

                      {orgType === "new" ? (
                        <div className={classes.fieldWrapper}>
                          <FormControl className={classes.formField}>
                            <Typography className={classes.label}>
                              Organization Name
                            </Typography>
                            <TextField
                              name="orgName"
                              id="orgName"
                              {...getFieldProps("orgName")}
                              variant="outlined"
                              size="small"
                            />
                            <ErrorMessage
                              name="orgName"
                              component={FormErrors}
                            />
                          </FormControl>

                          <FormControl
                            component="fieldset"
                            className={classes.formField}
                          >
                            <Typography className={classes.label}>
                              Upload Organization Photo
                            </Typography>
                            <DropzoneArea
                              style={{ border: "3px dashed blue !important" }}
                              acceptedFiles={["image/*"]}
                              dropzoneText={"Drag and Drop or Click here"}
                              maxFileSize={3000000}
                              showPreviewsInDropzone
                              // useChipsForPreview
                              showFileNames
                              showFileNamesInPreview
                              getDropRejectMessage={() =>
                                "File should be the required format and not more than 3MB"
                              }
                              filesLimit={1}
                              onChange={(file) => setOrganizationLogo(file[0])}
                            />
                            <div className={classes.uploadInfo}>
                              <Typography variant="caption">
                                Files Supported: PNG, Jpeg, PDF{" "}
                              </Typography>
                              <Typography variant="caption">
                                Maximum Size: 3MB
                              </Typography>
                            </div>
                            {/* <ErrorMessage name="loanDocuments.id" component={FormErrors} /> */}
                          </FormControl>

                          <FormControl className={classes.formField}>
                            <Typography className={classes.label}>
                              About Organization
                            </Typography>
                            <TextField
                              inputProps={{
                                maxLength: 500,
                              }}
                              name="aboutTheOrganization"
                              id="aboutTheOrganization"
                              multiline
                              minRows={5}
                              {...getFieldProps("aboutTheOrganization")}
                              variant="outlined"
                              size="small"
                            />
                            <ErrorMessage
                              name="aboutTheOrganization"
                              component={FormErrors}
                            />
                          </FormControl>
                        </div>
                      ) : (
                        <div className={classes.fieldWrapper}>
                          <FormControl
                            className={classes.formField}
                            variant="outlined"
                            size="small"
                          >
                            <Typography className={classes.label}>
                              Select Organization
                            </Typography>

                            <Select
                              name="orgId"
                              id="orgId"
                              {...getFieldProps("orgId")}
                              placeholder="Select Organization"
                            >
                              {organizationList.map((org, index) => (
                                <MenuItem key={index} value={org.id}>
                                  {org.orgName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      )}
                    </Box>
                  )}

                  <Box marginTop="2rem">
                    <Typography variant="h5">Initiative Information</Typography>
                    <Divider style={{ margin: "0.5rem 0rem" }} />
                    <div className={classes.fieldWrapper}>
                      <FormControl className={classes.formField}>
                        <Typography className={classes.label}>
                          Initiative Name
                        </Typography>
                        <TextField
                          name="name"
                          id="name"
                          {...getFieldProps("name")}
                          variant="outlined"
                          size="small"
                        />
                        <ErrorMessage name="name" component={FormErrors} />
                      </FormControl>

                      <FormControl
                        component="fieldset"
                        className={classes.formField}
                      >
                        <Typography className={classes.label}>
                          Upload Initiative Image
                        </Typography>
                        <DropzoneArea
                          style={{ border: "3px dashed blue !important" }}
                          acceptedFiles={["image/*"]}
                          dropzoneText={"Drag and Drop or Click here"}
                          maxFileSize={3000000}
                          showPreviewsInDropzone
                          // useChipsForPreview
                          showFileNames
                          showFileNamesInPreview
                          getDropRejectMessage={() =>
                            "File should be the required format and not more than 3MB"
                          }
                          filesLimit={1}
                          onChange={(file) => setInitiativeLogo(file[0])}
                        />
                        <div className={classes.uploadInfo}>
                          <Typography variant="caption">
                            Files Supported: PNG, Jpeg, PDF{" "}
                          </Typography>
                          <Typography variant="caption">
                            Maximum Size: 3MB
                          </Typography>
                        </div>
                        {/* <ErrorMessage name="loanDocuments.id" component={FormErrors} /> */}
                      </FormControl>

                      <FormControl className={classes.formField}>
                        <Typography className={classes.label}>
                          About Initiative
                        </Typography>
                        <TextField
                          inputProps={{
                            maxLength: 500,
                          }}
                          name="about"
                          id="about"
                          multiline
                          minRows={5}
                          {...getFieldProps("about")}
                          variant="outlined"
                          size="small"
                        />
                        <ErrorMessage name="about" component={FormErrors} />
                      </FormControl>

                      <FormControl className={classes.formField}>
                        <Typography className={classes.label}>
                          The Impact
                        </Typography>
                        <TextField
                          inputProps={{
                            maxLength: 500,
                          }}
                          name="theImpact"
                          id="theImpact"
                          multiline
                          minRows={5}
                          {...getFieldProps("theImpact")}
                          variant="outlined"
                          size="small"
                        />
                        <ErrorMessage name="theImpact" component={FormErrors} />
                      </FormControl>
                    </div>
                  </Box>

                  <Box align="right" marginTop={6} style={{ width: "100%" }}>
                    <Box className={classes.buttons}>
                      <Button
                        variant="outlined"
                        padding={"0.7rem 2rem"}
                        style={{ marginRight: "1rem" }}
                        disabled={isSubmitting}
                        color="primary"
                        onClick={goBack}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        padding={"0.7rem 2rem"}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        type="submit"
                        color="primary"
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>

            {id && (
              <Box marginTop="2rem">
                <Typography variant="h5">Initiative Status</Typography>
                <Divider style={{ margin: "0.5rem 0rem" }} />
                <Box marginTop="1rem">
                  <FormControl
                    style={{ display: "block", marginBottom: "1rem" }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isVetted}
                          onChange={updateVettedStatus}
                          disabled={isVettedSubmitting}
                        />
                      }
                      label={isVetted ? "Vetted" : "Not Vetted"}
                    />
                    <Box display="block" color="#81909D" fontSize="14px">
                      <Typography>
                        {isVetted
                          ? "This initiative has been vetted and reassures the public of its validity"
                          : "This initiative has NOT been vetted"}
                      </Typography>
                    </Box>
                  </FormControl>

                  <FormControl style={{ display: "block" }}>
                    <RadioGroup
                      aria-labelledby="initiative-status-group"
                      name="radio-buttons-group"
                      row
                      value={initiativeStatus}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio color="primary" />}
                        label="Draft"
                        onChange={() => setInitiativeStatus(1)}
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio color="primary" />}
                        label="Live"
                        onChange={() => setInitiativeStatus(2)}
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio color="primary" />}
                        label="Close"
                        onChange={() => setInitiativeStatus(3)}
                      />
                      <FormControlLabel
                        value={4}
                        control={<Radio color="primary" />}
                        label="Deactivate"
                        onChange={() => setInitiativeStatus(4)}
                      />
                    </RadioGroup>

                    <StatusLabel status={status} />
                  </FormControl>
                </Box>

                <Box align="right" marginTop={6} style={{ width: "100%" }}>
                  <Box className={classes.buttons}>
                    <Button
                      variant="contained"
                      padding={"0.7rem 2rem"}
                      onClick={updateInitiativeStatus}
                      color="primary"
                      loading={isStatusSubmitting}
                      disabled={isStatusSubmitting}
                    >
                      Update Status
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AddEditInitiative;
