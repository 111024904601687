import {
  Box,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Paper from "../../../../Paper";
import TableHead from "../../../../Reusables/TableHead";
import Table from "../../../../Reusables/Table";
import Pagination from "../../../../Reusables/Pagination";
import loanContext from "../../../../../Contexts/loan/loanContext";
import { validateDates } from "../../../../../Actions/helpers";
import { debounce } from "lodash";
import alertContext from "../../../../../Contexts/alert/alertContext";
import Button from "../../../../Button";
import { useStyles } from "./style";
import ModalDialog from "../../../../Reusables/Dialog";
import { Close } from "@material-ui/icons";
import { Link } from "react-router-dom/cjs/react-router-dom";

const ActiveInitiatives = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [org, setOrg] = useState(null);
  const [orgs, setOrgs] = useState({});

  const { makeFilterRequest } = useContext(loanContext);
  const { setAlert } = useContext(alertContext);

  const initialState = {
    Name: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState({ ...initialState });
  const { pageSize, pageNumber, startDate, endDate, Name } = payload;

  const _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }

    getOrgs();
    return () => (_isMounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  const triggerModalOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //To fix an issue of having data being set to null first before closing modal
    if (open === false) {
      org !== null && setOrg(null);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);

  const handleViewDetails = (organization) => {
    console.log("The selected org is: ", organization);
    setOrg(organization);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //always reset pageNumber if pageSize or name changes to avoid wrong data fetch
    if (name === "pageSize" || name === "orgName") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleOrgNameSearch = (e) => {
    setPayload({ ...payload, Name: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `Initiatives/GetAllInitiativeWithDonors?pageSize=${pageSize}&page=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the Name field
    setPayload({ ...initialState, Name });
    filter = true;
    if (Name !== "") {
      url += `&Name=${Name}`;
    }
    await getOrgs(url, filter);
  };

  const getdataFromFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getOrgs();
  };

  const getOrgs = async (
    url = `Initiatives/GetAllInitiativeWithDonors?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (Name !== "") {
        url += `&Name=${Name}`;
      }

      if (!validateDates(startDate, endDate)) {
        alert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      if (_isMounted.current) {
        console.log("Initiative Response data is: ", res.data.responseData);
        setOrgs(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    open && handleClose();
    filter = false;
  };

  const StatusComponent = ({ status }) => {
    const getColor = (status) => {
      let colorPalette = {};
      switch (status) {
        case "PENDING":
          colorPalette = {
            color: "#D49924",
            backgroundColor: "#FBF4EA",
          };
          break;
        case "LIVE":
          colorPalette = {
            color: "#41D87D",
            backgroundColor: "#EAFBF1",
          };
          break;
        case "CLOSE":
          colorPalette = {
            color: "#E43535",
            backgroundColor: "#FCE9E9",
          };
          break;
        case "DEACTIVATE":
          colorPalette = {
            color: "#868686",
            backgroundColor: "#F4F4F4",
          };
          break;

        default:
          break;
      }

      return colorPalette;
    };

    return (
      <Box
        style={{
          padding: "10px",
          borderRadius: "3px",
          textAlign: "center",
          ...getColor(status),
        }}
      >
        {status}
      </Box>
    );
  };

  const loadWithDebounce = useCallback(
    debounce((Name) => {
      if (Name === "") {
        getOrgs(url);
      } else {
        getOrgs(`${url}&Name=${Name}`);
      }
    }, 1000),
    []
  );

  const columns = [
    {
      Header: "Initiative",
      accessor: "initiative.name",
    },
    {
      Header: "Organization",
      accessor: "initiative.organization.orgName",
    },
    {
      Header: "Amount",
      accessor: "totalAmountDonated",
    },
    {
      Header: "Donors",
      accessor: "totalDonors",
    },
    {
      Header: "Date Created",
      accessor: "initiative.dateCreated",
      Cell: (props) => <span>{new Date(props.value).toDateString()}</span>,
    },
    {
      Header: "Status",
      accessor: "initiative.status",
      Cell: (props) => <StatusComponent status={props.value} />,
    },
    {
      Header: " ",
      Cell: (props) => (
        <Link
          style={{ color: "#117AF3", textDecoration: "none" }}
          to={{
            pathname: "/scholarships/initiatives/details",
            state: {
              data: props.row.original,
            },
          }}
        >
          <span>View Details</span>
        </Link>
      ),
    },
  ];

  const filterForm = () => (
    <Box style={{ width: "100%", minWidth: "300px" }}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">Filter</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>
          <Grid container spacing={2} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="startDate" className={classes.label}>
                  Start Date
                </label>
                <TextField
                  name="startDate"
                  variant="outlined"
                  size="small"
                  type="date"
                  value={startDate}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="endDate" className={classes.label}>
                  End Date
                </label>
                <TextField
                  name="endDate"
                  variant="outlined"
                  size="small"
                  type="date"
                  value={endDate}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box className={classes.filterActions}>
            <Typography
              style={{ color: "red", cursor: "pointer" }}
              onClick={clearFilters}
            >
              Clear all filters
            </Typography>
            <Button
              variant="contained"
              size="medium"
              disabled={loading || startDate === "" || endDate === ""}
              loading={loading}
              // type="submit"
              color="primary"
              onClick={() => getdataFromFirstPage()}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
      <Paper borderRadius={"0"} padding={"2rem"}>
        <TableHead
          title="Total Active Initiatives"
          searchItem={Name}
          onChange={handleOrgNameSearch}
          loading={loading}
          placeholder="Search By Initiative Name"
          totalSize={orgs.totalSize}
          onClick={() => triggerModalOpen()}
          handleInputChange={handleInputChange}
        />

        <Box marginTop={"2rem"}>
          {loading && !orgs.items ? (
            <p>Loading...</p>
          ) : (!loading && orgs.items) || (loading && orgs.items) ? (
            <>
              <Table columns={columns} data={orgs.items} loading={loading} />
              <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                <Grid item xs={12} md={3}>
                  {orgs.items.length > 0 && (
                    <Typography>
                      Showing page {orgs.pageNumber} of{" "}
                      {Math.ceil(orgs?.totalSize / pageSize)}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={9}>
                  <Pagination
                    dataSize={orgs?.totalSize}
                    perPage={pageSize}
                    handlePageClick={handlePageClick}
                    forcePage={orgs.pageNumber}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Box>
      </Paper>
      <ModalDialog
        handleClose={handleClose}
        open={open}
        content={filterForm()}
      />
    </Box>
  );
};

export default ActiveInitiatives;
