import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "../../../../Paper";

const ScholarshipCount = ({ scholarshipCounts, loading, gridProps }) => {
  const classes = useStyles();
  const { totalAmountDonated, organizations, donors, initiatives } =
    scholarshipCounts;

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        {(initiatives || initiatives === 0) && (
          <Grid style={gridProps} item xs={6} sm={3}>
            <Paper padding={"0rem"} borderRadius={"0.3rem"}>
              <Box
                style={{ borderTop: "3px solid #5E4BCE" }}
                className={classes.countItem}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "#81909D" }}
                >
                  {" "}
                  {loading ? (
                    <Skeleton variant="text" width="100%" height={30} />
                  ) : (
                    "TOTAL INITIATIVES"
                  )}
                </Typography>
                <Typography variant="h4" style={{ marginTop: "1rem" }}>
                  {loading ? (
                    <Skeleton variant="text" width="50%" height={15} />
                  ) : (
                    initiatives
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}

        {(organizations || organizations === 0) && (
          <Grid style={gridProps} item xs={6} sm={3}>
            <Paper padding={"0rem"} borderRadius={"0.3rem"}>
              <Box
                style={{ borderTop: "3px solid #1B9098" }}
                className={classes.countItem}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "#81909D" }}
                >
                  {" "}
                  {loading ? (
                    <Skeleton variant="text" width="100%" height={30} />
                  ) : (
                    "TOTAL ORGANIZATIONS"
                  )}
                </Typography>
                <Typography variant="h4" style={{ marginTop: "1rem" }}>
                  {loading ? (
                    <Skeleton variant="text" width="50%" height={15} />
                  ) : (
                    organizations
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}

        {(donors || donors === 0) && (
          <Grid style={gridProps} item xs={6} sm={3}>
            <Paper padding={"0rem"} borderRadius={"0.3rem"}>
              <Box
                style={{ borderTop: "3px solid #B38400" }}
                className={classes.countItem}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "#81909D" }}
                >
                  {" "}
                  {loading ? (
                    <Skeleton variant="text" width="100%" height={30} />
                  ) : (
                    "TOTAL DONORS"
                  )}
                </Typography>
                <Typography variant="h4" style={{ marginTop: "1rem" }}>
                  {loading ? (
                    <Skeleton variant="text" width="50%" height={15} />
                  ) : (
                    donors
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}

        {(totalAmountDonated || totalAmountDonated === 0) && (
          <Grid style={gridProps} item xs={6} sm={3}>
            <Paper padding={"0rem"} borderRadius={"0.3rem"}>
              <Box
                style={{ borderTop: "3px solid #39B300" }}
                className={classes.countItem}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "#81909D" }}
                >
                  {" "}
                  {loading ? (
                    <Skeleton variant="text" width="100%" height={30} />
                  ) : (
                    "TOTAL AMOUNT DONATED"
                  )}
                </Typography>
                <Typography variant="h4" style={{ marginTop: "1rem" }}>
                  {loading ? (
                    <Skeleton variant="text" width="50%" height={15} />
                  ) : (
                    <span>&#8358; {totalAmountDonated}</span>
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ScholarshipCount;
