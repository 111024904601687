import React, { useReducer, useContext } from "react";
import LoanReducer from "./loanReducer";
import LoanContext from "./loanContext";
import AxiosRequest from "../../Services/AxiosRequests";
import AlertContext from "../alert/alertContext";
import { useHistory } from "react-router";

import {
  SET_LOADING,
  GET_PROVIDERS_SUCCESS,
  GET_SETTINGS_SUCCESS,
  GET_LOAN_SUCCESS,
  ACTION_ERROR,
  LOAN_ACTION_SUCCESS,
  LOAN_ACTION_ERROR,
  SET_SUBMITTING,
  CREATE_PROVIDER_SUCCESS,
  GET_PROVIDER_SUCCESS,
  CLEAR_CURRENT_PROVIDER,
  SET_CURRENT_PARTNER,
  REMOVE_CURRENT_PARTNER,
  GET_STATES_SUCCESS,
  SET_CURRENT_PROVIDER,
  GET_LOAN_TYPE_SUCCESS,
  SET_CURRENT_LOAN,
  SET_UPLOAD_TYPES,
} from "../types";

const LoanState = (props) => {
  const initialState = {
    isLoading: false,
    isSubmitting: false,
    providers: [],
    pSettings: [],
    currentProvider: null,
    redirectUrl: null,
    provider: null,
    currentPartner: {},
    providerSettings: {},
    providersSettings: [],
    states: [],
    availableLoanTypes: null,
    uploadTypes: [], // the document types to upload for the current loan provider.
    currentLoan: {}, // for storing the loan being used for uploading loan documents.
  };

  const [state, dispatch] = useReducer(LoanReducer, initialState);

  const { setAlert } = useContext(AlertContext);
  const history = useHistory();

  const setUploadTypes = (types) => {
    dispatch({
      type: SET_UPLOAD_TYPES,
      payload: types,
    });
  };

  const setCurrentLoan = (types) => {
    dispatch({
      type: SET_CURRENT_LOAN,
      payload: types,
    });
  };

  //get loan Providers
  const getProviders = async () => {
    setLoading();
    try {
      const res = await AxiosRequest("get", "Provider");
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_PROVIDERS_SUCCESS,
          payload: res.data.responseData.items,
        });
      } else {
        dispatch({
          type: ACTION_ERROR,
        });
      }
    } catch (err) {
      //  console.log(err)
      dispatch({
        type: ACTION_ERROR,
      });
    }
  };

  //create or edit a provider
  const createProvider = async (values, id) => {
    setIsSubmitting();
    try {
      const { logo, ...rest } = values;
      let returnData;

      //decide if its an edit or create call
      const res =
        id === null
          ? await AxiosRequest("post", `Provider/create`, rest)
          : await AxiosRequest("put", `Provider/${id}`, rest);

      if (res.data.requestSuccessful) {
        //we decide what we want to update the state with, if user uploaded or logo or not
        returnData = res.data.responseData;
        // if logo was uploaded
        if (logo !== undefined) {
          let loanDocData = new FormData();
          loanDocData.append("logo", logo);

          const uploadLogo = await AxiosRequest(
            "post",
            `Upload/logo?providerId=${res.data.responseData.id}`,
            loanDocData
          );

          if (!uploadLogo.data.requestSuccessful) {
            dispatch({
              type: LOAN_ACTION_ERROR,
            });
            return setAlert("logo upload failed, please try again", "error");
          } else {
            returnData = uploadLogo.data.responseData;
          }
        }
        dispatch({
          type: CREATE_PROVIDER_SUCCESS,
          payload: returnData,
        });
        history.push({
          pathname: "/providers",
        });
        const action = id !== null ? "updated" : "created";
        setAlert(`Provider ${action} Successfully`, "success");
      } else {
        dispatch({
          type: LOAN_ACTION_ERROR,
        });
        setAlert(res.data.message, "error");
      }
    } catch (err) {
      actionError(err);
    }
  };

  const getProvider = async (id) => {
    setLoading();
    try {
      const res = await AxiosRequest("get", `Provider/${id}`);
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_PROVIDER_SUCCESS,
          payload: res.data.responseData,
        });
      } else {
        dispatch({
          type: LOAN_ACTION_ERROR,
        });
        setAlert(res.data.message, "error");
      }
    } catch (err) {
      actionError(err);
    }
  };

  const getProvidersConfig = async () => {
    setLoading();
    try {
      const res = await AxiosRequest("get", "ProviderConfigurationSettings");
      if (res.data.requestSuccessful) {
        return res.data.responseData;
      } else {
        dispatch({
          type: ACTION_ERROR,
        });
      }
    } catch (err) {
      // console.log(err)
      actionError(err);
    }
  };

  //get All loan providers' settings
  const getProvidersSettings = async () => {
    setLoading();
    try {
      const res = await AxiosRequest("get", "ProviderSettings");
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_SETTINGS_SUCCESS,
          payload: res.data.responseData,
        });
      } else {
        dispatch({
          type: ACTION_ERROR,
        });
      }
    } catch (err) {
      // console.log(err)
      actionError(err);
    }
  };

  //create or edit a provider setting
  const createProviderSetting = async (payload, id, edit) => {
    try {
      //depending on edit value, decide HTTP method
      const method = edit ? "put" : "post";

      const { data } = await AxiosRequest(
        method,
        `ProviderSettings?providerId=${id}`,
        payload
      );
      if (data.requestSuccessful) {
        const action = edit ? "updated" : "created";
        setAlert(`Details ${action} successfully`, "success");
      } else {
        setAlert(data.message, "error");
      }
    } catch (err) {
      actionError(err);
    }
  };

  //create or edit a provider configuration
  const createProviderConfig = async (payload, sortCode) => {
    try {
      const { data } = await AxiosRequest(
        "post",
        `ProviderConfigurationSettings/create?sortCode=${sortCode}`,
        payload
      );
      if (data.requestSuccessful) {
        setAlert(`Details created successfully`, "success");
      } else {
        setAlert(data.message, "error");
      }
    } catch (err) {
      actionError(err);
    }
  };

  const updateProviderConfig = async (payload, id) => {
    try {
      const { data } = await AxiosRequest(
        "put",
        `ProviderConfigurationSettings/update/${id}`,
        payload
      );
      if (data.requestSuccessful) {
        setAlert(`Details updated successfully`, "success");
      } else {
        setAlert(data.message, "error");
      }
    } catch (err) {
      actionError(err);
    }
  };

  const setCurrentProvider = (payload) => {
    dispatch({
      type: SET_CURRENT_PROVIDER,
      payload,
    });
  };

  //clear current provider
  const clearProvider = () => {
    dispatch({ type: CLEAR_CURRENT_PROVIDER });
  };

  const allowProviderSelect = (loanRef, userId, partner) => {
    dispatch({
      type: GET_LOAN_SUCCESS,
      payload: null,
    });

    history.push({
      pathname: "/application",
      search: `?loanRef=${loanRef}&userId=${userId}`,
      state: { partner },
    });
  };

  const getAdminLoanByRef = async (loanRef, userId) => {
    try {
      const response = await AxiosRequest("get", "Provider");
      console.log("The list of providers is: ", response.data?.responseData);
      const providers = response.data?.responseData.items || [];

      const res = await AxiosRequest(
        "get",
        `LoanTransaction/loan/${loanRef}?userId=${userId}`
      );

      if (res.data.requestSuccessful) {
        console.log("The loan data is: ", res.data);
        const loanData = res.data.responseData;

        const currentProvider = providers.find(
          (el) => el.sortCode === loanData.providerCode
        );
        currentProvider && setCurrentProvider(currentProvider);
        dispatch({
          type: GET_LOAN_SUCCESS,
          payload: loanData,
        });

        console.log("The current loan provider found is: ", currentProvider);
      } else {
        console.log("The dat was not received: ", res, res.data);
      }
    } catch (error) {
      console.log("The dat was not received: ", error);
      actionError(error);
    }
  };

  //get A loan by reference number
  const getLoanByRef = async (loanRef, userId) => {
    setLoading();
    try {
      //Remove current partner from state if any
      dispatch({
        type: REMOVE_CURRENT_PARTNER,
      });
      const res = await AxiosRequest(
        "get",
        `LoanTransaction/loan/${loanRef}?userId=${userId}`
      );

      if (res.data.requestSuccessful) {
        // let response = res.data.responseData;

        // let partner = null;

        dispatch({
          type: GET_LOAN_SUCCESS,
          payload: null,
        });

        // --- WE NO LONGER REQUIRE A PROVIDER TO FILL A LOAN FORM, SO WE REMOVE THE IMPLEMENTATION BELOW. ---

        // if (response.loanRequest.partnerId) {
        //   partner = await getPartner(response.loanRequest.partnerId);
        //   //set the partner in state to use across
        //   if (partner?.status === "success") {
        //     dispatch({
        //       type: SET_CURRENT_PARTNER,
        //       payload: partner.data,
        //     });
        //   }
        // }
        // if (response.providerName === "") {
        //   // check for the partner to provider configuration by admin
        //   if (partner) {
        //     if (partner.data.providers.length === 1) {
        //       attachProviderToLoan(
        //         loanRef,
        //         partner.data.providers[0].sortCode,
        //         userId,
        //         true
        //       );
        //     } else {
        //       //Redirect to allow them select from list of providers
        //       allowProviderSelect(loanRef, userId, partner.data);
        //     }
        //   } else {
        //     allowProviderSelect(loanRef, userId);
        //   }
        // } else {
        //   //user has previously selected a provider
        //   let data = {
        //     providerName: response.providerName,
        //     redirectUrl: response.loanRequest.redirectUrl,
        //   };
        //   dispatch({
        //     type: GET_LOAN_SUCCESS,
        //     payload: data,
        //   });
        // }
      } else {
        dispatch({
          type: ACTION_ERROR,
        });
      }
    } catch (err) {
      // console.log(err)
      actionError(err);
    }
  };

  //Update an applicant's loan to disbursed or approved
  const approveOrDisburseLoan = async (loanRef, status) => {
    setIsSubmitting();
    let action = status === "APPROVED" ? "approve" : "disburse";
    try {
      const res = await AxiosRequest(
        "post",
        `LoanTransaction/${action}/${loanRef}`
      );
      if (res.data.requestSuccessful || res.data.isSuccessful) {
        dispatch({
          type: LOAN_ACTION_SUCCESS,
        });
        setAlert("Loan Status Updated Successfully", "success");
        history.push({
          pathname: "/dashboard",
        });
      } else {
        dispatch({
          type: LOAN_ACTION_ERROR,
        });
        setAlert(res.data.message, "error");
      }
    } catch (err) {
      actionError(err);
    }
  };

  //decline a user's loan request
  const declineLoan = async (payload) => {
    setIsSubmitting();
    try {
      const res = await AxiosRequest(
        "post",
        `LoanTransaction/decline/${payload.loanRef}`,
        {
          remark: payload.message,
        }
      );
      if (res.data.requestSuccessful) {
        dispatch({
          type: LOAN_ACTION_SUCCESS,
        });
        setAlert("Loan Declined Successfully", "success");
        history.push({
          pathname: "/dashboard",
        });
      } else {
        dispatch({
          type: LOAN_ACTION_ERROR,
        });
        setAlert(res.data.message, "error");
      }
    } catch (err) {
      actionError(err);
    }
  };

  //attach a provider to a loan
  const attachProviderToLoan = async (
    loanRef,
    providerCode,
    userId,
    reload
  ) => {
    setIsSubmitting();
    try {
      const res = await AxiosRequest(
        "post",
        `LoanTransaction/addProvider/${loanRef}`,
        {
          userId,
          providerCode,
        }
      );
      if (res.data.requestSuccessful) {
        dispatch({
          type: LOAN_ACTION_SUCCESS,
        });

        reload
          ? window.location.reload()
          : history.push(`/apply?loanRef=${loanRef}&userId=${userId}`);

        return;
      } else {
        dispatch({
          type: LOAN_ACTION_ERROR,
        });
        setAlert(res.data.message, "error");
      }
    } catch (err) {
      actionError(err);
    }
  };

  const getPartner = async (id) => {
    try {
      const res = await AxiosRequest("get", `Organization/${id}`);
      if (res.data.requestSuccessful) {
        return {
          data: res.data.responseData,
          status: "success",
        };
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLoanCounts = async () => {
    try {
      const loanCounts = await AxiosRequest("get", "Dashboard/loancounter");
      return loanCounts;
    } catch (error) {
      throw error;
    }
  };

  const getScholarshipCounts = async () => {
    try {
      const scholarshipCounts = await AxiosRequest(
        "get",
        "Dashboard/scholarship_counter"
      );
      return scholarshipCounts;
    } catch (error) {
      throw error;
    }
  };

  const getGraphReport = async (payload) => {
    try {
      const res = await AxiosRequest(
        "get",
        `Dashboard/dashboardGraph/${payload}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  };

  const getAuditLogData = async (id) => {
    // try {
    //   const auditLogData = await AxiosRequest("get", `AuditLog/${id}`)
    //   console.log(auditLogData.data.responseData.newValue)
    //   return auditLogData.data.responseData.newValue
    // } catch (err) {
    //   throw err
    // }
  };

  const makeFilterRequest = async (url) => {
    try {
      const res = await AxiosRequest("get", url);
      return res;
    } catch (err) {
      throw err;
    }
  };

  //create or update an Institution
  const addOrUpdateIntstitution = async (payload) => {
    try {
      let method = "post";
      let url = "Institution/Create";
      if (payload.id) {
        method = "put";
        url = `Institution/${payload.id}`;
        delete payload.id;
      }
      const res = await AxiosRequest(method, url, payload);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const addOrUpdateCustomers = async (payload) => {
    try {
      let method = "post";
      let url = "Partner/Create";
      if (payload.id) {
        method = "put";
        url = `Partner/edit/${payload.id}`;
        delete payload.id;
      }
      const res = await AxiosRequest(method, url, payload);
      return res;
    } catch (err) {
      throw err;
    }
  };

  // add or update a vendor (via two different APIs)
  const addOrUpdateVendors = async (payload) => {
    try {
      let method = "post";
      let url = "Vendor/signup_vendor";
      if (payload.id) {
        method = "put";
        url = `User/Update`;
      }
      const res = await AxiosRequest(method, url, payload);
      return res;
    } catch (err) {
      throw err;
    }
  };

  // add or update a vendor (via two different APIs)
  const addOrUpdateProduct = async (payload) => {
    try {
      let method = "post";
      let url = "Products/create_product";
      if (payload.id) {
        method = "put";
        url = `Products/edit_product/${payload.id}`;

        delete payload.id;
      }
      const res = await AxiosRequest(method, url, payload);
      return res;
    } catch (err) {
      throw err;
    }
  };

  // delete a partner
  const deleteCustomerDetails = async (id) => {
    try {
      let method = "delete";
      let url = `Partner/${id}`;

      const res = await AxiosRequest(method, url);
      return res;
    } catch (err) {
      throw err;
    }
  };

  // create/update document type.
  const addOrUpdateDocuments = async (payload) => {
    try {
      let method = "post";
      let url = "DocumentType/Create";
      if (payload.id) {
        method = "put";
        url = `DocumentType/${payload.id}`;
        delete payload.id;
      }
      const res = await AxiosRequest(method, url, payload);
      return res;
    } catch (err) {
      throw err;
    }
  };

  // delete document type
  const deleteDocumentDetails = async (id) => {
    try {
      let method = "delete";
      let url = `DocumentType/${id}`;

      const res = await AxiosRequest(method, url);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const addOrUpdateCategories = async (payload) => {
    try {
      let method = "post";
      let url = "Category/Create";
      if (payload.id) {
        method = "put";
        url = `Category/${payload.id}`;
        delete payload.id;
      }
      const res = await AxiosRequest(method, url, payload);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const deleteCategory = async (id) => {
    try {
      let method = "delete";
      let url = `Category/${id}`;

      const res = await AxiosRequest(method, url);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const reverseCategory = async (id) => {
    try {
      let method = "post";
      let url = `Category/reserve_deleted_category/${id}`;

      const res = await AxiosRequest(method, url);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const addOrUpdateBrands = async (payload) => {
    try {
      let method = "post";
      let url = "Brands/Create";
      if (payload.id) {
        method = "put";
        url = `Brands/${payload.id}`;
        delete payload.id;
      }
      const res = await AxiosRequest(method, url, payload);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const deleteBrand = async (id) => {
    try {
      let method = "delete";
      let url = `Brands/${id}`;

      const res = await AxiosRequest(method, url);
      return res;
    } catch (err) {
      throw err;
    }
  };

  //create or update a Partner (organization)
  const addOrUpdatePartner = async (payload, partnerId) => {
    const { Logo, ...rest } = payload;
    const formData = new FormData();
    formData.append("payload", JSON.stringify(rest));
    Logo !== undefined && formData.append("Logo", Logo);
    try {
      let method = "post";
      let url = "Organization/create";
      if (partnerId) {
        method = "put";
        url = `Organization/${partnerId}`;
      }
      const res = await AxiosRequest(method, url, formData);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const addOrUpdateScholarshipOrg = async (payload, scholarshipId) => {
    const { organizationLogo, ...rest } = payload;
    const formData = new FormData();
    formData.append("payload", JSON.stringify(rest));
    organizationLogo !== undefined &&
      formData.append("organizationLogo", organizationLogo);
    try {
      let method = "post";
      let url = "ScholarshipOrganization/create";
      if (scholarshipId) {
        method = "put";
        url = `ScholarshipOrganization/${scholarshipId}`;
      }
      const res = await AxiosRequest(method, url, formData);
      return res;
    } catch (err) {
      setAlert(
        err.response?.data?.message ||
          `Organization action error. Please try again`,
        "error"
      );
      throw err;
    }
  };

  const addOrUpdateScholarshipInitiative = async (
    payload,
    iSExistOrg,
    initiativeId
  ) => {
    const { organizationLogo, initiativeLogo, ...rest } = payload;
    const formData = new FormData();
    formData.append("payload", JSON.stringify(rest));
    organizationLogo !== undefined &&
      formData.append("organizationLogo", organizationLogo);
    initiativeLogo !== undefined &&
      formData.append("initiativeLogo", initiativeLogo);
    try {
      let method = "post";
      let url = iSExistOrg
        ? "Initiatives/create_initiative_with_existing_organization"
        : "Initiatives/create_new_initiative";
      if (initiativeId) {
        method = "put";
        url = `Initiatives/${initiativeId}`;
      }
      const res = await AxiosRequest(method, url, formData);
      return res;
    } catch (err) {
      setAlert(
        err.response?.data?.message ||
          `Organization action error. Please try again`,
        "error"
      );
      throw err;
    }
  };

  const deleteProductDetails = async (id) => {
    try {
      let method = "delete";
      let url = `Products/delete_product/${id}`;

      const res = await AxiosRequest(method, url);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const addOrUpdateStore = async (payload, storeId) => {
    try {
      let method = "post";
      let url = "Stores/create_store";
      if (storeId) {
        method = "put";
        url = `Stores/update_store/${storeId}`;
      }
      const res = await AxiosRequest(method, url, payload);
      return res;
    } catch (err) {
      throw err;
    }
  };

  //get the available loan types
  const getLoanTypes = async () => {
    try {
      const res = await AxiosRequest("get", "/AvailableLoanType");
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_LOAN_TYPE_SUCCESS,
          payload: res.data.responseData.items,
        });
      } else {
        dispatch({
          type: ACTION_ERROR,
        });
      }
    } catch (err) {
      // console.log(err)
      throw err;
    }
  };

  const getStates = async () => {
    try {
      const { data } = await AxiosRequest("get", "StaticData/regions");
      if (data.requestSuccessful) {
        dispatch({
          type: GET_STATES_SUCCESS,
          payload: data.responseData.items,
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const getCities = async (regionId) => {
    try {
      const { data } = await AxiosRequest(
        "get",
        `StaticData/GetCitiesByRegionId/${regionId}`
      );
      if (data.requestSuccessful) {
        return data.responseData.items;
      }
    } catch (err) {
      throw err;
    }
  };

  const actionError = (err) => {
    console.log(err);
    // setAlert(errMsg, "error");
    dispatch({ type: ACTION_ERROR });
    throw err;
  };

  // set submitting
  const setIsSubmitting = () => dispatch({ type: SET_SUBMITTING });

  //set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <LoanContext.Provider
      value={{
        ...state,
        getProvidersSettings,
        getProviders,
        getLoanByRef,
        getAdminLoanByRef,
        approveOrDisburseLoan,
        declineLoan,
        createProvider,
        clearProvider,
        createProviderSetting,
        createProviderConfig,
        getProvidersConfig,
        updateProviderConfig,
        getProvider,
        attachProviderToLoan,
        setCurrentProvider,
        getLoanCounts,
        getScholarshipCounts,
        getGraphReport,
        getAuditLogData,
        addOrUpdateIntstitution,
        addOrUpdateCustomers,
        addOrUpdatePartner,
        deleteCustomerDetails,
        makeFilterRequest,
        getStates,
        getCities,
        getLoanTypes,
        setCurrentLoan,
        setUploadTypes,
        setIsSubmitting,
        addOrUpdateDocuments,
        addOrUpdateVendors,
        addOrUpdateStore,
        deleteDocumentDetails,
        addOrUpdateCategories,
        deleteCategory,
        reverseCategory,
        addOrUpdateBrands,
        deleteBrand,
        deleteProductDetails,
        addOrUpdateProduct,
        addOrUpdateScholarshipOrg,
        addOrUpdateScholarshipInitiative,
      }}
    >
      <>{props.children}</>
    </LoanContext.Provider>
  );
};

export default LoanState;
