import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, List, ListItem, Collapse, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useStyles } from "./style";

const SidebarNav = (props) => {
  const { pages } = props;
  const classes = useStyles();

  const [openTab, setOpenTab] = useState([]);
  const location = useLocation();

  const isActive = (href) => {
    const newHref = href.substring(1);
    const newPathname = location.pathname.split("/")[1];

    return newHref === newPathname;
  };

  return (
    <List component="nav" className={classes.root}>
      {pages.map((page, i) =>
        !page.nested ? (
          <Box
            key={i}
            className={
              isActive(page.href) ? classes.activeItem : classes.navItem
            }
          >
            <Link to={page.href} className={classes.link}>
              <ListItem>
                <Typography display="inline" className={classes.icon}>
                  {page.icon}
                </Typography>{" "}
                {/* <ListItemText */}
                <Typography className={classes.title}>{page.title}</Typography>
              </ListItem>
            </Link>
          </Box>
        ) : (
          <Box key={i}>
            <ListItem
              onClick={() =>
                // openTabs.includes(i)
                //   ? setOpenTabs(openTabs.filter((tab) => tab !== i))
                //   : setOpenTabs([...openTabs, i])
                openTab === i ? setOpenTab("") : setOpenTab(i)
              }
              className={classes.parentNav}
            >
              <Box className={classes.subParent}>
                <Box style={{ display: "flex" }}>
                  <Typography>{page.icon}</Typography>{" "}
                  <Typography className={classes.parentNavText}>
                    {page.title}
                  </Typography>
                </Box>
                <Box>
                  {openTab === i ? <ExpandLess /> : <ExpandMore />}
                  {/* {openTabs.includes(i) ? <ExpandLess /> : <ExpandMore />} */}
                </Box>
              </Box>
            </ListItem>
            {/* <Collapse in={openTabs.includes(i)} timeout="auto" unmountOnExit> */}
            <Collapse in={openTab === i} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {page.nested.map((nested, i) => (
                  <Box
                    key={i}
                    className={
                      isActive(nested.href)
                        ? classes.activeItem
                        : classes.navItem
                    }
                  >
                    <Link to={nested.href} className={classes.link}>
                      <ListItem className={classes.nested}>
                        <Typography display="inline" className={classes.icon}>
                          {nested.icon}
                        </Typography>{" "}
                        <Typography className={classes.title}>
                          {nested.title}
                        </Typography>
                      </ListItem>
                    </Link>
                  </Box>
                ))}
              </List>
            </Collapse>
          </Box>
        )
      )}
    </List>
  );
};

export default SidebarNav;

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired,
};
