import React, { useState, useEffect } from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import Paper from "../../../../Paper";
import { useHistory } from "react-router-dom";
import { KeyboardArrowLeft } from "@material-ui/icons";
import Button from "../../../../Button";
import OrganizationCount from "../ScholarshipCount/organizationCount";
import { Link } from "react-router-dom";
import DonationHistory from "./donationHistory";
import AxiosRequest from "../../../../../Services/AxiosRequests";

const DonorDetails = (props) => {
  const donorId =
    (props.location?.state?.data && props.location?.state?.data.donorId) || "";

  const [donorDetails, setDonorDetails] = useState({});
  const classes = useStyles();
  const history = useHistory();

  const { fullName, email, country, state, city, postalCode } =
    donorDetails || {};

  console.log("The donor details are", donorDetails);

  const goBack = () => {
    history.goBack();
  };

  const donorImageProps = {
    height: "10rem",
    width: "10rem",
    borderRadius: "50%",
    backgroundColor: "#D9D9D9",
    color: "#FAFAFA",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "50px",
  };

  const donorInitials = () => {
    let initials =
      fullName
        ?.split(" ")
        ?.map((name) => name.charAt(0))
        ?.join("") || "";
    if (initials.length > 2) {
      initials = initials.substring(0, 2);
    }

    console.log("The initials are: ", initials);

    return initials;
  };

  donorInitials();

  useEffect(() => {
    let isMounted = true;
    const fetchDonorDetails = async () => {
      try {
        const res = await AxiosRequest("get", `Donor/${donorId}`);

        if (res.data.requestSuccessful && res.data.responseData && isMounted) {
          setDonorDetails(res.data.responseData);
        }
      } catch (error) {
        console.log("Error occured: ", error);
      }
    };

    fetchDonorDetails();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Box>
      <Typography style={{ marginBottom: "0.5rem" }} variant="body1">
        <span style={{ fontWeight: "300" }}>Scholarships</span> /{" "}
        <span style={{ fontWeight: "300" }}>Organizations</span> /{" "}
        <span style={{ fontWeight: "500" }}>{fullName}</span>
      </Typography>
      <Box
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerTexts}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={goBack}
            aria-label="close"
            className={classes.backButton}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="h4">{fullName}</Typography>
        </Box>
      </Box>
      <Box>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <Box>
            <Typography variant="h5">Donor Information</Typography>
            <Divider style={{ margin: "0.5rem 0rem" }} />
            <Box className={classes.detailsContainer}>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Box style={donorImageProps}>{donorInitials()}</Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container>
                    <Grid item xs={6} sm={4}>
                      <Box marginBottom="1rem">
                        <Typography gutterBottom variant="subtitle2">
                          Full Name
                        </Typography>
                        <Typography variant="body1">{fullName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box marginBottom="1rem">
                        <Typography gutterBottom variant="subtitle2">
                          Country
                        </Typography>
                        <Typography variant="body1">{country}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box marginBottom="1rem">
                        <Typography gutterBottom variant="subtitle2">
                          City
                        </Typography>
                        <Typography variant="body1">{city}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box marginBottom="1rem">
                        <Typography gutterBottom variant="subtitle2">
                          Email
                        </Typography>
                        <Typography variant="body1">{email}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box marginBottom="1rem">
                        <Typography gutterBottom variant="subtitle2">
                          State
                        </Typography>
                        <Typography variant="body1">{state}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <Box marginBottom="1rem">
                        <Typography gutterBottom variant="subtitle2">
                          Postal Code
                        </Typography>
                        <Typography variant="body1">{postalCode}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5">Donation History</Typography>
            <Divider style={{ margin: "0.5rem 0rem" }} />
            <Box marginY="1rem" className={classes.initiativesContainer}>
              <Grid spacing={2} container>
                <Grid item xs={12}>
                  <DonationHistory donorId={donorId} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default DonorDetails;
