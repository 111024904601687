import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import loanContext from "../../../../../Contexts/loan/loanContext";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "../../../../Paper";

const OrganizationCount = ({ scholarshipCounts, loading, gridProps }) => {
  const classes = useStyles();
  const { amountRaised, amountReceived, donors } = scholarshipCounts;

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        {(donors || donors === 0) && (
          <Grid style={gridProps} item xs={12}>
            <Paper padding={"0rem"} borderRadius={"0.3rem"}>
              <Box
                style={{ borderTop: "3px solid #B38400" }}
                className={classes.countItem}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "#81909D" }}
                >
                  {" "}
                  {loading ? (
                    <Skeleton variant="text" width="100%" height={30} />
                  ) : (
                    "TOTAL DONORS"
                  )}
                </Typography>
                <Typography variant="h4" style={{ marginTop: "1rem" }}>
                  {loading ? (
                    <Skeleton variant="text" width="50%" height={15} />
                  ) : (
                    donors
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}

        {(amountRaised || amountRaised === 0) && (
          <Grid style={gridProps} item xs={12}>
            <Paper padding={"0rem"} borderRadius={"0.3rem"}>
              <Box
                style={{ borderTop: "3px solid #39B300" }}
                className={classes.countItem}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "#81909D" }}
                >
                  {" "}
                  {loading ? (
                    <Skeleton variant="text" width="100%" height={30} />
                  ) : (
                    "TOTAL AMOUNT RAISED"
                  )}
                </Typography>
                <Typography variant="h4" style={{ marginTop: "1rem" }}>
                  {loading ? (
                    <Skeleton variant="text" width="50%" height={15} />
                  ) : (
                    <span>&#8358; {amountRaised}</span>
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}

        {(amountReceived || amountReceived === 0) && (
          <Grid style={gridProps} item xs={12}>
            <Paper padding={"0rem"} borderRadius={"0.3rem"}>
              <Box
                style={{ borderTop: "3px solid #C72A2A" }}
                className={classes.countItem}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "#81909D" }}
                >
                  {" "}
                  {loading ? (
                    <Skeleton variant="text" width="100%" height={30} />
                  ) : (
                    "TOTAL AMOUNT RECEIVED"
                  )}
                </Typography>
                <Typography variant="h4" style={{ marginTop: "1rem" }}>
                  {loading ? (
                    <Skeleton variant="text" width="50%" height={15} />
                  ) : (
                    <span>&#8358; {amountReceived}</span>
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default OrganizationCount;
