import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "1.5rem",
  },
  countItem: {
    borderRadius: "0.5rem",
    padding: "1.2rem 1rem 2.3rem",
    [theme.breakpoints.down("xs")]: {
      minHeight: "12rem",
    },
  },
  countPaper: {
    marginBottom: "0.5rem",
  },
}));

export { useStyles };
