import React, { useState } from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import Paper from "../../../../Paper";
import { useHistory } from "react-router-dom";
import { KeyboardArrowLeft } from "@material-ui/icons";
import Button from "../../../../Button";
import OrganizationCount from "../ScholarshipCount/organizationCount";
import { Link } from "react-router-dom";
import vettedIcon from "../../../../../Assets/images/vetted-icon.png";

const InitiativeDetails = (props) => {
  const initiativeDetails =
    props.location?.state?.data && props.location?.state?.data;
  const { initiative, totalAmountDonated, totalDonors } =
    initiativeDetails || {};
  const {
    about,
    name,
    status,
    logoUrl,
    theImpact,
    vetted,
    donors,
    organization: { about: orgAbout, logoUrl: orgLogoUrl, orgName },
  } = initiative || {};

  const [donorCards, setDonorCards] = useState([
    {
      donorName: "Named Donor",
      donorAmount: "50000",
      dateCreated: "09-26-2023",
    },
    {
      donorName: "Donor Update",
      donorAmount: "100000",
      dateCreated: "08-22-2023",
    },
    {
      donorName: "Named Donor",
      donorAmount: "50000",
      dateCreated: "09-26-2023",
    },
    {
      donorName: "Donor Update",
      donorAmount: "100000",
      dateCreated: "08-22-2023",
    },
    {
      donorName: "Named Donor",
      donorAmount: "50000",
      dateCreated: "09-26-2023",
    },
    {
      donorName: "Donor Update",
      donorAmount: "100000",
      dateCreated: "08-22-2023",
    },
    {
      donorName: "Named Donor",
      donorAmount: "50000",
      dateCreated: "09-26-2023",
    },
    {
      donorName: "Donor Update",
      donorAmount: "100000",
      dateCreated: "08-22-2023",
    },
    {
      donorName: "Named Donor",
      donorAmount: "50000",
      dateCreated: "09-26-2023",
    },
    {
      donorName: "Donor Update",
      donorAmount: "100000",
      dateCreated: "08-22-2023",
    },
    {
      donorName: "Named Donor",
      donorAmount: "50000",
      dateCreated: "09-26-2023",
    },
    {
      donorName: "Donor Update",
      donorAmount: "100000",
      dateCreated: "08-22-2023",
    },
    {
      donorName: "Named Donor",
      donorAmount: "50000",
      dateCreated: "09-26-2023",
    },
    {
      donorName: "Donor Update",
      donorAmount: "100000",
      dateCreated: "08-22-2023",
    },
  ]);
  const classes = useStyles();
  const history = useHistory();

  console.log("Initiative details: ", initiativeDetails);

  const goBack = () => {
    history.goBack();
  };

  const VettedComponent = () => {
    return (
      <span className={classes.vettedText}>
        <img style={{ paddingRight: "5px" }} src={vettedIcon} />
        Vetted
      </span>
    );
  };

  const StatusComponent = ({ status }) => {
    const getColor = (status) => {
      let colorPalette = {};
      switch (status) {
        case "PENDING":
          colorPalette = {
            color: "#D49924",
            backgroundColor: "#FBF4EA",
          };
          break;
        case "LIVE":
          colorPalette = {
            color: "#41D87D",
            backgroundColor: "#EAFBF1",
          };
          break;
        case "CLOSE":
          colorPalette = {
            color: "#E43535",
            backgroundColor: "#FCE9E9",
          };
          break;
        case "DEACTIVATE":
          colorPalette = {
            color: "#868686",
            backgroundColor: "#F4F4F4",
          };
          break;

        default:
          break;
      }

      return colorPalette;
    };

    return (
      <Box
        style={{
          padding: "7px",
          borderRadius: "3px",
          textAlign: "center",
          ...getColor(status),
        }}
      >
        {status}
      </Box>
    );
  };

  const DonorsCard = ({ donorDetails }) => {
    const { donorName, donorAmount, dateCreated } = donorDetails || {};
    return (
      <Box className={classes.donorCardContainer}>
        <Typography variant="h5">{donorName}</Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography
            style={{
              fontWeight: "300",
            }}
            variant="body1"
          >
            &#8358; {donorAmount}
          </Typography>
          <Typography
            style={{
              fontWeight: "300",
            }}
            variant="body1"
          >
            {new Date(dateCreated).toDateString()}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <Typography style={{ marginBottom: "0.5rem" }} variant="body1">
        <span style={{ fontWeight: "300" }}>Scholarships</span> /{" "}
        <span style={{ fontWeight: "300" }}>Organizations</span> /{" "}
        <span style={{ fontWeight: "500" }}>{name}</span>
      </Typography>
      <Box
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerTexts}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={goBack}
            aria-label="close"
            className={classes.backButton}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="h4">{name}</Typography>
        </Box>
        <Link
          style={{ color: "#117AF3", textDecoration: "none" }}
          to={{
            pathname: "/scholarships/initiatives/add",
            state: {
              data: initiativeDetails?.initiative,
            },
          }}
        >
          <Button variant="outlined" color="primary">
            Edit Initiative
          </Button>
        </Link>
      </Box>
      <Box>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <Box marginBottom="2rem">
            <Typography variant="h5">Initiative Information</Typography>
            <Divider style={{ margin: "0.5rem 0rem" }} />
            <Box className={classes.detailsContainer}>
              <Grid container>
                <Grid item xs={12} sm={9}>
                  <Grid container>
                    <Box maxWidth="650px" width="100%" marginRight="2rem">
                      <Grid item xs={12}>
                        <Box position="relative" height="250px">
                          <img
                            width="100%"
                            height="100%"
                            alt="organization logo"
                            src={logoUrl}
                          />
                          {vetted && <VettedComponent />}
                          <Box position="absolute" bottom="1rem" right="1rem">
                            <StatusComponent status={status} />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box marginTop="2rem" marginBottom="2rem">
                          <Typography gutterBottom variant="subtitle2">
                            About Initiative
                          </Typography>
                          <Typography
                            style={{
                              maxWidth: "400px",
                              wordWrap: "break-word",
                            }}
                            variant="body1"
                          >
                            {about}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box marginBottom="2rem">
                          <Typography gutterBottom variant="subtitle2">
                            The Impact
                          </Typography>
                          <Typography
                            style={{
                              maxWidth: "400px",
                              wordWrap: "break-word",
                            }}
                            variant="body1"
                          >
                            {theImpact}
                          </Typography>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <OrganizationCount
                    scholarshipCounts={{
                      donors: totalDonors,
                      amountRaised: totalAmountDonated,
                    }}
                    loading={false}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box marginBottom="2rem">
            <Typography variant="h5">Organization Information</Typography>
            <Divider style={{ margin: "0.5rem 0rem" }} />
            <Box className={classes.detailsContainer}>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <img
                    width="100%"
                    height="auto"
                    style={{ maxHeight: "200px" }}
                    alt="organization logo"
                    src={orgLogoUrl}
                  />
                </Grid>
                <Grid className={classes.detailslHeader2} item xs={12} sm={3}>
                  <Box marginBottom="1rem">
                    <Typography gutterBottom variant="subtitle2">
                      Name
                    </Typography>
                    <Typography variant="body1">{orgName}</Typography>
                  </Box>
                </Grid>
                <Grid className={classes.detailslHeader2} item xs={12} sm={6}>
                  <Box marginBottom="1rem">
                    <Typography gutterBottom variant="subtitle2">
                      About
                    </Typography>
                    <Typography
                      style={{ maxWidth: "400px", wordWrap: "break-word" }}
                      variant="body1"
                    >
                      {orgAbout}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5">Donors({totalDonors})</Typography>
            <Divider style={{ margin: "0.5rem 0rem" }} />
            {totalDonors ? (
              <Box className={classes.donorContainer} marginY="1rem">
                <Grid container spacing={2}>
                  {donors?.map((donor, index) => (
                    <Grid item xs={6} sm={3} key={index}>
                      <DonorsCard donorDetails={donor} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <div></div>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default InitiativeDetails;
