import React from "react";
import { Switch, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoutes";
import Layout from "../Components/Layout";
// import Steps from "../Pages/Steps";
import TandC from "../Pages/TandC";
import PageNotFound from "../Pages/PageNotFound";
import AdminLogin from "../Pages/AdminPages/AdminLogin";
import AdminActivateUser from "../Pages/AdminPages/AdminActivateUser";
import AdminForgotPassword from "../Pages/AdminPages/AdminForgotPassword";
import AdminResetPassword from "../Pages/AdminPages/AdminResetPassword";
import AdminHome from "../Pages/AdminPages/AdminHome";
import AdminReport from "../Pages/AdminPages/AdminReport";
import AdminProviders from "../Pages/AdminPages/AdminProviders";
import AdminRole from "../Pages/AdminPages/AdminRole";
import AdminInstitutions from "../Pages/AdminPages/AdminInstitutions";
import AdminCustomers from "../Pages/AdminPages/AdminCustomers";
import AdminDocuments from "../Pages/AdminPages/AdminDocuments";
import AdminOrganizations from "../Pages/AdminPages/AdminOrganizations";
import AdminUsers from "../Pages/AdminPages/AdminUsers";
import AdminAudit from "../Pages/AdminPages/AdminAudit";
import Audit from "../Pages/AdminPages/Audit";
import AdminBanks from "../Pages/AdminPages/AdminBanks";
import ProviderSelect from "../Pages/UserPages/ProviderSeclect";
import LoanApplication from "../Pages/UserPages/LoanApplication";
import ProfileUser from "../Pages/UserPages/ProfileUser";
import Provider from "../Pages/AdminPages/Provider";
import ViewLoan from "../Pages/AdminPages/ViewLoan";
import EditLoan from "../Pages/AdminPages/EditLoan";
import Applicants from "../Pages/AdminPages/Applicants";
import Profile from "../Pages/AdminPages/Profile";
import Feedback from "../Pages/AdminPages/Feedback";
import Referrals from "../Pages/AdminPages/Referrals";
import AdminPartners from "../Pages/AdminPages/AdminPartners";
import AdminCreatePartner from "../Pages/AdminPages/AdminCreatePartner";
import AdminViewPartner from "../Pages/AdminPages/AdminViewPartner";

import SuccessPage from "../Components/Layout/UsersLayouts/SuccessPage";
import AdminFaq from "../Pages/AdminPages/AdminFaq";
import AddEditRole from "../Components/Layout/AdminLayouts/RoleLayout/AddEditRole";
import LoanDocuments from "../Components/Layout/UsersLayouts/CredPalForm/ApplicationInformation/LoanDocuments";
import AdminVendors from "../Pages/AdminPages/AdminVendors";
import AdminStores from "../Pages/AdminPages/AdminStores";
import AdminViewStore from "../Pages/AdminPages/AdminViewStore";
import AdminCreateStore from "../Pages/AdminPages/AdminCreateStore";
import AdminCategories from "../Pages/AdminPages/AdminCategories";
import AdminBrands from "../Pages/AdminPages/AdminBrands";
import AdminProducts from "../Pages/AdminPages/AdminProducts";
import AdminScholarships from "../Pages/AdminPages/AdminScholarships";
import AddEditOrganization from "../Components/Layout/AdminLayouts/ScholarshipsLayout/Organizations/addEdit";
import OrganizationDetails from "../Components/Layout/AdminLayouts/ScholarshipsLayout/Organizations/details";
import AddEditInitiative from "../Components/Layout/AdminLayouts/ScholarshipsLayout/Initiatives/addEdit";
import InitiativeDetails from "../Components/Layout/AdminLayouts/ScholarshipsLayout/Initiatives/details";
import DonorDetails from "../Components/Layout/AdminLayouts/ScholarshipsLayout/Donors/details";

const Routes = () => {
  return (
    <Switch>
      {/* Auth Routes */}
      <Route exact path="/adminlogin" component={AdminLogin} />
      <Route
        exact
        path="/user/forgot-password"
        component={AdminForgotPassword}
      />
      <Route exact path="/user/activate" component={AdminActivateUser} />
      <Route exact path="/user/reset-password" component={AdminResetPassword} />

      {/* Applicants' Routes */}
      <PublicRoute exact path="/terms-and-conditions" component={TandC} />
      <PublicRoute exact path="/application" component={ProviderSelect} />
      <PublicRoute exact path="/application/success" component={SuccessPage} />
      <PublicRoute exact path="/apply" component={LoanApplication} />
      <PublicRoute exact path="/apply2" component={LoanDocuments} />
      <PublicRoute exact path="/user/profile" component={ProfileUser} />

      {/* Admin Routes */}
      {/* <ProtectedRoute exact path="/olddashboard" component={AdminHome} layout={Layout} /> */}
      <ProtectedRoute
        exact
        path="/dashboard"
        component={AdminHome}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/report"
        component={AdminReport}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/providers"
        component={AdminProviders}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/partners"
        component={AdminPartners}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/partner/details"
        component={AdminViewPartner}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/partners/create"
        component={AdminCreatePartner}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/users/admin"
        component={AdminUsers}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/users/applicants"
        component={Applicants}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/users/roles"
        component={AdminRole}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/users/roles/action"
        component={AddEditRole}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/institutions"
        component={AdminInstitutions}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/customers"
        component={AdminCustomers}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/vendors"
        component={AdminVendors}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/stores"
        component={AdminStores}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/categories"
        component={AdminCategories}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/brands"
        component={AdminBrands}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/products"
        component={AdminProducts}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/store/details"
        component={AdminViewStore}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/store/create"
        component={AdminCreateStore}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/documents"
        component={AdminDocuments}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/banks"
        component={AdminBanks}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/organizations"
        component={AdminOrganizations}
        layout={Layout}
      />
      <ProtectedRoute exact path="/loan" component={ViewLoan} layout={Layout} />
      <ProtectedRoute
        exact
        path="/loan/edit"
        component={EditLoan}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/provider/:id"
        component={Provider}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/provider"
        component={Provider}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/profile"
        component={Profile}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/feedback"
        component={Feedback}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/referrals"
        component={Referrals}
        layout={Layout}
      />
      <ProtectedRoute exact path="/faqs" component={AdminFaq} layout={Layout} />
      <ProtectedRoute
        exact
        path="/audit"
        component={AdminAudit}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/scholarships"
        component={AdminScholarships}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/scholarships/organizations/add"
        component={AddEditOrganization}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/scholarships/organizations/details"
        component={OrganizationDetails}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/scholarships/initiatives/add"
        component={AddEditInitiative}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/scholarships/initiatives/details"
        component={InitiativeDetails}
        layout={Layout}
      />
      <ProtectedRoute
        exact
        path="/scholarships/donors/details"
        component={DonorDetails}
        layout={Layout}
      />
      {/* <ProtectedRoute exact path="/audit/:id" component={Audit} layout={Layout} /> */}
      <Route path="*" component={PageNotFound} />
      {/* <Route path="/" component={Steps} /> */}
    </Switch>
  );
};

export default Routes;

// REVISIT THIS - ISSUE WAS THAT THE NESTED SIDEBARS WERE COLLAPSING

// import React from "react";
// import { Switch, Route } from "react-router-dom";
// import ProtectedRoute from "./ProtectedRoute";
// import PublicRoute from "./PublicRoutes";
// import Layout from "../Components/Layout";
// import Steps from "../Pages/Steps";
// import TandC from "../Pages/TandC";
// import PageNotFound from "../Pages/PageNotFound";
// import AdminLogin from "../Pages/AdminPages/AdminLogin";
// import AdminActivateUser from "../Pages/AdminPages/AdminActivateUser";
// import AdminForgotPassword from "../Pages/AdminPages/AdminForgotPassword";
// import AdminResetPassword from "../Pages/AdminPages/AdminResetPassword";
// import AdminHome from "../Pages/AdminPages/AdminHome";
// import AdminReport from "../Pages/AdminPages/AdminReport";
// import AdminProviders from "../Pages/AdminPages/AdminProviders";
// import AdminRole from "../Pages/AdminPages/AdminRole";
// import AdminInstitutions from "../Pages/AdminPages/AdminInstitutions";
// import AdminOrganizations from "../Pages/AdminPages/AdminOrganizations";
// import AdminUsers from "../Pages/AdminPages/AdminUsers";
// import AdminAudit from "../Pages/AdminPages/AdminAudit";
// import Audit from "../Pages/AdminPages/Audit";
// import AdminBanks from "../Pages/AdminPages/AdminBanks";
// import ProviderSelect from "../Pages/UserPages/ProviderSeclect";
// import LoanApplication from "../Pages/UserPages/LoanApplication";
// import ProfileUser from "../Pages/UserPages/ProfileUser";
// import Provider from "../Pages/AdminPages/Provider";
// import Loan from "../Pages/AdminPages/Loan";
// import Applicants from "../Pages/AdminPages/Applicants";
// import Profile from "../Pages/AdminPages/Profile";
// import Feedback from "../Pages/AdminPages/Feedback";
// import Referrals from "../Pages/AdminPages/Referrals";
// import AdminPartners from "../Pages/AdminPages/AdminPartners";

// import SuccessPage from "../Components/Layout/UsersLayouts/SuccessPage";

// const Routes = () => {
//   const adminRoutes = [
//     {
//       path: "/adminlogin",
//       component: AdminLogin,
//     },
//     {
//       path: "/user/forgot-password",
//       component: AdminForgotPassword,
//     },
//     {
//       path: "/user/forgot-password",
//       component: AdminForgotPassword,
//     },
//     {
//       path: "/user/reset-password",
//       component: AdminResetPassword,
//     },
//     {
//       path: "/terms-and-conditions",
//       component: TandC,
//     },
//     {
//       path: "/user/activate",
//       component: AdminActivateUser,
//     },
//     {
//       path: "/dashboard",
//       component: AdminHome,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/report",
//       component: AdminReport,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/providers",
//       component: AdminProviders,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/partners",
//       component: AdminPartners,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/role",
//       component: AdminRole,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/users/admin",
//       component: AdminUsers,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/users/applicants",
//       component: Applicants,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/institutions",
//       component: AdminInstitutions,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/banks",
//       component: AdminBanks,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/organizations",
//       component: AdminOrganizations,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/loan",
//       component: Loan,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/provider",
//       component: Provider,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/provider/:id",
//       component: Provider,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/profile",
//       component: Profile,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/feedback",
//       component: Feedback,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/referrals",
//       component: Referrals,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/audit",
//       component: AdminAudit,
//       layout: true,
//       auth: true,
//     },
//     {
//       path: "/audit/:id",
//       component: Audit,
//       layout: true,
//       auth: true,
//     },
//   ];

//   const userRoutes = [
//     {
//       path: "/application",
//       component: ProviderSelect,
//     },
//     {
//       path: "/application/success",
//       component: SuccessPage,
//     },
//     {
//       path: "/apply",
//       component: LoanApplication,
//     },
//     {
//       path: "/user/profile",
//       component: ProfileUser,
//     },
//     {
//       path: "*",
//       component: PageNotFound,
//     },
//   ];

//   return (
//     <Switch>
//       {adminRoutes.map(({ path, component, layout, auth }, key) =>
//         auth ? (
//           <ProtectedRoute
//             exact
//             path={path}
//             component={component}
//             layout={layout && Layout}
//             key={key}
//           />
//         ) : (
//           <Route exact path={path} component={component} key={key} />
//         )
//       )}

//       {userRoutes.map(({ path, component }, key) => (
//         <PublicRoute exact path={path} component={component} key={key} />
//       ))}
//     </Switch>
//   );
// };

// export default Routes;
