import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 3, 0, 3),
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 3, 3),
  },
  formControl: {
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  filterActions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
    alignItems: "center",
  },
  label: {
    marginBottom: "0.35em",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  headerTexts: {
    display: "flex",
    alignItems: "center",
    margin: "1.5rem 0px",
  },
  backButton: {
    padding: "0.5rem",
    marginRight: "1rem",
  },
  addFormContainer: {
    marginTop: "1rem",
  },
  formField: {
    width: `48%`,
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  fieldWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  label: {
    fontSize: "13px",
    fontWeight: "400",
    marginBottom: ".3rem",
  },
  buttons: {
    width: "40%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  detailsContainer: {
    display: "flex",
    padding: "1rem 0rem",
    marginBottom: "1rem",
  },
  detailslHeader2: {
    paddingLeft: "1rem",
  },
  initiativeCardContainer: {
    padding: "0.5rem",
    border: "1px solid #BAC2C9",
    borderRadius: "5px",
  },
  vettedText: {
    fontSize: "0.7rem",
    fontWeight: "400",
    lineHeight: "1.5",
    backgroundColor: "#fff",
    padding: "5px",
    borderRadius: "5px",
    display: "inline-flex",
    alignItems: "center",
    position: "absolute",
    top: "1rem",
    right: "1rem",
  },
  donorContainer: {
    padding: "10px",
    backgroundColor: "#F5F8FE",
  },
  donorCardContainer: {
    padding: "10px",
    borderRadius: "3px",
    backgroundColor: "#fff",
  },
}));

export { useStyles };
