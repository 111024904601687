import React, { useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./style";
import * as Yup from "yup";
import Paper from "../../../../Paper";
import { ErrorMessage, Form, Formik } from "formik";
import FormErrors from "../../../../FormErrors";
import { DropzoneArea } from "material-ui-dropzone";
import Button from "../../../../Button";
import { useHistory } from "react-router-dom";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { useContext } from "react";
import alertContext from "../../../../../Contexts/alert/alertContext";
import loanContext from "../../../../../Contexts/loan/loanContext";

const AddEditOrganization = (props) => {
  const [organizationLogo, setOrganizationLogo] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const { setAlert } = useContext(alertContext);
  const { addOrUpdateScholarshipOrg } = useContext(loanContext);

  const orgDetails = props.location?.state?.data && props.location?.state?.data;
  const {
    about,
    amountRaised,
    amountReceived,
    donors,
    id,
    initiatives,
    logoUrl,
    orgName: orgTitle,
  } = orgDetails || {};

  const initialValues = {
    orgName: orgTitle || "",
    about: about || "",
  };
  const validationSchema = Yup.object({
    orgName: Yup.string().required("The organization name is empty"),
    about: Yup.string().required("About organization is empty"),
  });

  const submitForm = async (values, actions) => {
    console.log("The submit button is called.", values, organizationLogo);
    if (!id && !organizationLogo) {
      actions.setSubmitting(false);
      return setAlert("Upload an organization logo", "error");
    }

    try {
      const payload = { ...values, organizationLogo };
      const response = await addOrUpdateScholarshipOrg(payload, id && id);
      if (response.data?.requestSuccessful) {
        setAlert("Organization action was successful.", "success");
        actions.setSubmitting(false);
        history.push("/scholarships");
      } else {
        setAlert("Organization action unsuccessful. Please try again", "error");
        actions.setSubmitting(false);
      }
    } catch (error) {}
  };

  const goBack = () => {
    console.log("The cancel save is implemented");
    history.goBack();
  };

  return (
    <Box>
      <Typography style={{ marginBottom: "0.5rem" }} variant="body1">
        <span style={{ fontWeight: "300" }}>Scholarships</span> /{" "}
        <span style={{ fontWeight: "500" }}>Organization</span>
      </Typography>
      <Box className={classes.headerTexts}>
        <IconButton
          onClick={goBack}
          aria-label="close"
          className={classes.backButton}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <Typography variant="h4">
          {" "}
          {`${id ? "Edit" : "Add"} Organization`}
        </Typography>
      </Box>
      <Box>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <Typography>Organization Information</Typography>
          <Divider style={{ margin: "0.5rem 0rem" }} />
          <Box className={classes.addFormContainer}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitForm}
            >
              {({
                values,
                errors,
                touched,
                getFieldProps,
                setFieldValue,
                isSubmitting,
              }) => (
                <Form noValidate>
                  <div className={classes.fieldWrapper}>
                    <FormControl className={classes.formField}>
                      <Typography className={classes.label}>
                        Organization Name
                      </Typography>
                      <TextField
                        name="orgName"
                        id="orgName"
                        {...getFieldProps("orgName")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="orgName" component={FormErrors} />
                    </FormControl>

                    <FormControl
                      component="fieldset"
                      className={classes.formField}
                    >
                      <Typography className={classes.label}>
                        Upload Organization Photo
                      </Typography>
                      <DropzoneArea
                        style={{ border: "3px dashed blue !important" }}
                        acceptedFiles={["image/*"]}
                        dropzoneText={"Drag and Drop or Click here"}
                        maxFileSize={3000000}
                        showPreviewsInDropzone
                        // useChipsForPreview
                        showFileNames
                        showFileNamesInPreview
                        getDropRejectMessage={() =>
                          "File should be the required format and not more than 3MB"
                        }
                        filesLimit={1}
                        onChange={(file) => setOrganizationLogo(file[0])}
                      />
                      <div className={classes.uploadInfo}>
                        <Typography variant="caption">
                          Files Supported: PNG, Jpeg, PDF{" "}
                        </Typography>
                        <Typography variant="caption">
                          Maximum Size: 3MB
                        </Typography>
                      </div>
                      {/* <ErrorMessage name="loanDocuments.id" component={FormErrors} /> */}
                    </FormControl>

                    <FormControl className={classes.formField}>
                      <Typography className={classes.label}>
                        About Organization
                      </Typography>
                      <TextField
                        inputProps={{
                          maxLength: 500,
                        }}
                        name="about"
                        id="about"
                        multiline
                        minRows={5}
                        {...getFieldProps("about")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="about" component={FormErrors} />
                    </FormControl>
                  </div>

                  <Box align="right" marginTop={6} style={{ width: "100%" }}>
                    <Box className={classes.buttons}>
                      <Button
                        variant="outlined"
                        padding={"0.7rem 2rem"}
                        style={{ marginRight: "1rem" }}
                        disabled={isSubmitting}
                        color="primary"
                        onClick={goBack}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        padding={"0.7rem 2rem"}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        type="submit"
                        color="primary"
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default AddEditOrganization;
