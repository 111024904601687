import React from "react";
import { Box, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import Paper from "../../../../Paper";
import { useHistory } from "react-router-dom";
import { KeyboardArrowLeft } from "@material-ui/icons";
import Button from "../../../../Button";
import OrganizationCount from "../ScholarshipCount/organizationCount";
import { Link } from "react-router-dom";

const OrganizationDetails = (props) => {
  const orgDetails = props.location?.state?.data && props.location?.state?.data;
  const {
    about,
    initiativeAmountRaised,
    initiativeAmountReceived,
    totalDonors,
    id,
    initiative,
    logoUrl,
    orgName: orgTitle,
  } = orgDetails;
  const classes = useStyles();
  const history = useHistory();

  console.log("Org details: ", orgDetails);

  const goBack = () => {
    history.goBack();
  };

  const StatusComponent = ({ status }) => {
    const getColor = (status) => {
      let colorPalette = {};
      switch (status) {
        case "PENDING":
          colorPalette = {
            color: "#D49924",
            backgroundColor: "#FBF4EA",
          };
          break;
        case "ACTIVE":
          colorPalette = {
            color: "#41D87D",
            backgroundColor: "#EAFBF1",
          };
          break;
        case "CLOSED":
          colorPalette = {
            color: "#E43535",
            backgroundColor: "#FCE9E9",
          };
          break;
        case "INACTIVE":
          colorPalette = {
            color: "#868686",
            backgroundColor: "#F4F4F4",
          };
          break;

        default:
          break;
      }

      return colorPalette;
    };

    return (
      <Box
        style={{
          padding: "7px",
          borderRadius: "3px",
          textAlign: "center",
          ...getColor(status),
        }}
      >
        {status}
      </Box>
    );
  };

  const InitiativesCard = ({ data }) => {
    const { amountRaised, amountReceived, donors, initiative } = data || {};

    return (
      <Box className={classes.initiativeCardContainer}>
        <Grid container>
          <Grid item xs={4}>
            <img
              src={initiative.initiativeLogoUrl}
              width="100%"
              height="100%"
              style={{ maxHeight: "150px" }}
            />
          </Grid>
          <Grid style={{ padding: "0px 5px" }} item xs={8}>
            <Box marginBottom="1rem">
              <Typography variant="h5">{initiative.initiativeName}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box marginBottom="1rem">
                <Typography gutterBottom variant="subtitle2">
                  Amount Raised
                </Typography>
                <Typography variant="body1">&#8358; {amountRaised}</Typography>
              </Box>
              <Box marginBottom="1rem">
                <Typography gutterBottom variant="subtitle2">
                  Supporters
                </Typography>
                <Typography variant="body1">{donors}</Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box marginBottom="1rem">
                <Typography variant="body1">
                  {new Date(initiative.dateCreated).toDateString()}
                </Typography>
              </Box>
              <Box marginBottom="1rem">
                <StatusComponent status="PENDING" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      <Typography style={{ marginBottom: "0.5rem" }} variant="body1">
        <span style={{ fontWeight: "300" }}>Scholarships</span> /{" "}
        <span style={{ fontWeight: "300" }}>Organizations</span> /{" "}
        <span style={{ fontWeight: "500" }}>{orgTitle}</span>
      </Typography>
      <Box
        justifyContent="space-between"
        alignItems="center"
        className={classes.headerTexts}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={goBack}
            aria-label="close"
            className={classes.backButton}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Typography variant="h4">{orgTitle}</Typography>
        </Box>
        <Link
          style={{ color: "#117AF3", textDecoration: "none" }}
          to={{
            pathname: "/scholarships/organizations/add",
            state: {
              data: orgDetails,
            },
          }}
        >
          <Button variant="outlined" color="primary">
            Edit Organization
          </Button>
        </Link>
      </Box>
      <Box>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <Box>
            <Typography variant="h5">Organization Information</Typography>
            <Divider style={{ margin: "0.5rem 0rem" }} />
            <Box className={classes.detailsContainer}>
              <Grid container>
                <Grid item xs={12} sm={9}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <img
                        width="100%"
                        height="auto"
                        style={{ maxHeight: "200px" }}
                        alt="organization logo"
                        src={logoUrl}
                      />
                    </Grid>
                    <Grid
                      className={classes.detailslHeader2}
                      item
                      xs={12}
                      sm={9}
                    >
                      <Box marginBottom="1rem">
                        <Typography gutterBottom variant="subtitle2">
                          Name
                        </Typography>
                        <Typography variant="body1">{orgTitle}</Typography>
                      </Box>

                      <Box marginBottom="1rem">
                        <Typography gutterBottom variant="subtitle2">
                          About
                        </Typography>
                        <Typography
                          style={{ maxWidth: "400px", wordWrap: "break-word" }}
                          variant="body1"
                        >
                          {about}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <OrganizationCount
                    scholarshipCounts={{
                      donors: totalDonors,
                      amountRaised: initiativeAmountRaised,
                      amountReceived: initiativeAmountReceived,
                    }}
                    loading={false}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box>
            <Typography variant="h5">Initiatives</Typography>
            <Divider style={{ margin: "0.5rem 0rem" }} />
            <Box marginY="1rem" className={classes.initiativesContainer}>
              <Grid spacing={2} container>
                <Grid item xs={12} md={6}>
                  {initiative?.map?.((data) => (
                    <InitiativesCard data={data} />
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default OrganizationDetails;
