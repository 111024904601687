import React, { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@material-ui/core";
import Button from "../../../Button";

import { useStyles } from "./style";
import { useHistory } from "react-router-dom";
import ScholarshipCount from "./ScholarshipCount";
import Organizations from "./Organizations";
import { useEffect, useContext } from "react";
import loanContext from "../../../../Contexts/loan/loanContext";
import Initiatives from "./Initiatives";
import Donors from "./Donors";
import ActiveDonors from "./Donors/activeDonors";
import ActiveInitiatives from "./Initiatives/activeInitiatives";

const ScholarshipLayout = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tabView, setTabView] = useState(0);

  const { getScholarshipCounts } = useContext(loanContext);

  const [loading, setLoading] = useState(false);
  const [scholarshipCounts, setScholarshipCounts] = useState({});

  let isMounted = true;
  useEffect(() => {
    getScholarshipCountsData();
    return () => (isMounted = false);
  }, []);

  const getScholarshipCountsData = async () => {
    setLoading(true);
    try {
      const res = await getScholarshipCounts();
      if (isMounted) {
        setScholarshipCounts(res.data.payload);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  ////////////////////////**********TAB ACTIONS**********///////////////////
  const handleTabChange = (event, value) => {
    setTabView(value);
  };

  const handleButtonClick = () => {
    console.log("The button is clicked.");
    switch (tabView) {
      case 0:
        history.push("/scholarships/organizations/add");
        break;

      case 1:
        history.push("/scholarships/initiatives/add");
        break;

      case 3:
        history.push("/scholarships/donors/add");
        break;

      default:
        break;
    }
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  function allyProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Scholarships</Typography>
        <Box>
          {tabView === 0 ? (
            <Button
              style={{ marginRight: "1rem" }}
              variant="contained"
              size="medium"
              color="primary"
              onClick={handleButtonClick}
            >
              Add Organization
            </Button>
          ) : tabView === 1 ? (
            <Button
              style={{ marginRight: "1rem" }}
              variant="contained"
              size="medium"
              color="primary"
              onClick={handleButtonClick}
            >
              Add Initiative
            </Button>
          ) : (
            <></>
          )}

          <Button
            variant="outlined"
            size="medium"
            color="primary"
            onClick={handleButtonClick}
          >
            Export
          </Button>
        </Box>
      </Box>

      <ScholarshipCount
        scholarshipCounts={scholarshipCounts}
        loading={loading}
      />

      <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
        <Tabs
          value={tabView}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="loan documents tab"
        >
          <Tab label="Organizations" {...allyProps(1)} />
          <Tab label="Initiatives" {...allyProps(0)} />
          <Tab label="Active Initiatives" {...allyProps(2)} />
          <Tab label="Donors" {...allyProps(3)} />
          <Tab label="Active Donors" {...allyProps(4)} />
        </Tabs>
      </Box>

      <TabPanel value={tabView} index={0}>
        <Organizations />
      </TabPanel>

      <TabPanel value={tabView} index={1}>
        <Initiatives />
      </TabPanel>

      <TabPanel value={tabView} index={2}>
        <ActiveInitiatives />
      </TabPanel>

      <TabPanel value={tabView} index={3}>
        <Donors />
      </TabPanel>

      <TabPanel value={tabView} index={4}>
        <ActiveDonors />
      </TabPanel>
    </Box>
  );
};

export default ScholarshipLayout;
