import React from "react";

import { Dialog, useMediaQuery } from "@material-ui/core";
import { useStyles } from "./style";
import { useTheme } from "@material-ui/styles";

const ModalDialog = ({ handleClose, open, content, maxWidth, fullWidth }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = useStyles();
  return (
    <div>
      <Dialog
        maxWidth={maxWidth}
        fullWidth={fullWidth || isMobile}
        // maxWidth = {'xs'}
        classes={{
          root: classes.root,
        }}
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        {content}
      </Dialog>
    </div>
  );
};

export default ModalDialog;
